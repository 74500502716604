.ourServices {
  @apply m-0 py-36 px-8 text-center;
}

.deaprtment {
  @apply flex gap-8 flex-col lg:flex-row md:text-start mt-2 mb-16 -mx-3 sm:mx-0;
}

.deaprtment > img {
  @apply lg:max-w-[500px] lg:min-w-[340px] lg:w-[30%] aspect-[4/3] h-full object-cover rounded-2xl;
}

.departmentDes {
  @apply flex flex-col justify-between flex-grow gap-4 sm:gap-6;
}

.numbers {
  @apply flex gap-4 flex-col md:flex-row;
}

.numbers > div {
  @apply bg-dodger-blue bg-opacity-10 flex items-center justify-center py-2 px-4 rounded-md md:w-max;
}

.numbers img{
  @apply mr-2 rtl:mr-0 rtl:ml-2
}