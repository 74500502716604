.applyForm {
  @apply pt-28 lg:pt-32;
}

.applyForm .paper {
  @apply grid lg:grid-cols-2 gap-x-6;
}

.jobHeader {
  @apply mb-8 col-span-full flex flex-col;
}
