.complain {
  @apply p-4 sm:p-8 mb-16 sm:px-16 bg-[#DBDCDC] flex items-center justify-around gap-10;
}

.complain > img {
  @apply rounded-full hidden lg:inline-block object-contain w-1/2 h-[575px];
}

.formWra {
  @apply flex flex-col items-center gap-6 text-nowrap w-full lg:w-auto;
}

.formWra input {
  @apply bg-white w-full px-6 py-2.5 rounded-lg sm:w-[400px] md:w-[466px];
}

.formWra textarea {
  @apply h-[200px] w-full px-6 py-2.5 resize-none rounded-lg sm:w-[400px] md:w-[466px];
}

.formWra .complainHeader2 {
  @apply text-white font-bold my-2 mt-1 text-[25px] sm:text-[30px] md:text-[40px];
}
.complainHeader2 span {
  @apply text-dodger-blue;
}

.formWra button {
  @apply rounded-lg py-2.5 mt-2 w-full text-white bg-dodger-blue hover:bg-opacity-90 transition-colors sm:w-[400px] md:w-[466px] disabled:bg-medium-gray disabled:cursor-not-allowed;
}
