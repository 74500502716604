.homepage {
  @apply z-0 relative pt-4 text-center lg:text-start;
}

.paper {
  @apply m-4 lg:mt-10 lg:m-8 p-4 lg:p-8 bg-white rounded-2xl relative shadow-[0px_0px_100px_-40px_#00000040];
}

.blueBox {
  @apply text-base sm:text-xl text-dodger-blue bg-dodger-blue bg-opacity-5 p-0 mb-2 mt-6 py-2 px-3 rounded-full w-max self-center;
}

.secondaryTitle {
  @apply text-base sm:text-2xl;
}

.centeredText {
  @apply text-center;
}

.profession {
  @apply mb-2;
}