.pay {
  @apply flex flex-col h-full;
}

.payRadioGroup {
  @apply divide-y-2 mt-6 mb-2;
}

.payRadioGroup label {
  @apply flex items-center justify-between gap-4 cursor-pointer hover:bg-gray-50 -mx-2 px-2 py-2.5;
}

.payRadioGroup label input {
  @apply h-5 w-5 cursor-pointer accent-dodger-blue;
}

.paymentMethodInfo {
  @apply flex items-center gap-3;
}

.paymentMethodInfo img {
  @apply h-[52px] w-[52px] bg-dodger-blue rounded-full bg-opacity-5 p-3;
}
