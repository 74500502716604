.intro {
  @apply font-inter rtl:font-rubik h-[100svh] max-w-[100vw] relative overflow-hidden text-start mb-8;
}

.imWra {
  @apply absolute overflow-hidden;
}

.imWra > img {
  @apply max-h-[100vh] h-screen max-h-[100svh] h-[100svh] w-screen object-fill;
}

.intro .desktopEq {
  @apply hidden md:block rtl:-scale-100;
}

.intro .mobileEq {
  @apply block md:hidden;
}

.introTextWra {
  @apply px-[4%] text-black z-10 font-[700] text-[40px] sm:text-[60px] lg:text-[86px] xl:text-[96px] leading-[60px] sm:leading-[80px] lg:leading-[100px] xl:leading-[119px] max-w-[700px] absolute mt-[18vh] md:mt-[0px] md:top-1/2 md:-translate-y-1/2 box-content;
}

.introTextWra span {
  @apply text-inherit text-dodger-blue;
}

.introTextWra a {
  @apply rounded-lg bg-dodger-blue px-[35.5px]  lg:px-[139.5px] text-base lg:text-2xl py-4 leading-6 text-white;
}

.intro p {
  @apply text-inherit;
}
