.applyForAJobWra {
  @apply pt-32 lg:pt-36 pb-8;
}
.applyForAJob {
  @apply flex flex-col my-0;
}

.jobsWra {
  @apply grid md:grid-cols-2 gap-8 mt-8;
}
