.bookAService {
  @apply m-0 pt-36 pb-8 flex flex-col lg:flex-row p-4 sm:px-8 gap-4 sm:gap-8 min-h-[100vh] lg:max-h-[100vh];
}

.bookAService > img {
  @apply lg:w-[40%] max-h-[300px] sm:max-h-[500px] lg:max-h-none object-cover min-h-full rounded-2xl;
}

.servicedets {
  @apply lg:w-[60%] flex flex-col-reverse md:flex-row flex-grow gap-8 md:max-h-[80vh] lg:max-h-none;
}
