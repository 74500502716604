.providerCard {
  @apply border-[5px] rounded-2xl p-2.5 w-[360px] flex-grow flex flex-col justify-between;
}

.providerCard > img {
  @apply w-full object-cover max-h-[300px] min-h-[180px];
}

.bookServiceLink {
  @apply bg-dodger-blue rounded-lg text-white py-2 px-3 h-min lg:min-w-[120px];
}

.providerServices {
  @apply flex gap-2 mt-1 flex-wrap;
}

.providerService {
  @apply py-0.5 px-2.5 border rounded-2xl;
}

.providerRating {
  @apply flex items-center gap-2;
}

.providerCardText {
  @apply text-start flex flex-col gap-2 my-3 lg:mb-0;
}

.providerCardColFlex {
  @apply flex flex-col lg:flex-row lg:items-end gap-4 justify-between;
}
