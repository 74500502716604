.addAService {
  @apply pb-[115px];
}
.addButton {
  @apply text-dodger-blue flex flex-col items-center absolute rounded-b-2xl;
}

.addButton > img {
  @apply p-2 sm:p-4 border-[5px] rounded-2xl border-dodger-blue;
}
