.comment {
  @apply pt-8;
}

.commentTag {
  @apply flex items-start gap-5 w-full;
}

.commentTag > div {
  @apply pt-2 flex-grow;
}

.commentTag .duration {
  @apply text-[#BFBFBF] inline-block mx-5 mb-3;
}

.commentContent {
  @apply pb-5 border-b-2 border-[#D4D4D4] w-full;
}
