.flexWra{
    @apply flex items-center justify-between
}

.forgotPass{
    @apply underline underline-offset-2
}

.DontHaveAccount{
    @apply text-center mt-3
}
