.line{
  @apply flex items-center gap-3
}

.ratingBar {
  @apply relative h-[6px] bg-white overflow-hidden rounded-lg flex-grow;
}

.ratingBar > div {
  @apply h-full bg-[#F7B217];
}
