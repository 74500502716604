.drawer {
  @apply absolute h-[100vh] top-0 left-0 z-20;
}

:dir(rtl) .drawer {
  @apply right-0 left-auto;
}

.drawerList {
  @apply absolute h-[100vh] bg-dodger-blue z-10 transition-transform duration-500 w-max;
}

.drawback {
  @apply -translate-x-full;
}

:dir(rtl) .drawback {
  @apply translate-x-full;
}

.toggler {
  @apply absolute w-[100vw] h-[100vh] z-0 transition-all duration-500;
}

.paddingoWra {
  @apply px-6 pb-2 pt-4;
}

.locationWra {
  @apply px-6 pl-[26px] pb-0;
}

.drawerRoutes {
  @apply w-full pt-3;
}

.drawerRoutes > li {
  @apply w-full;
}

.drawerRoutes > li > a {
  @apply w-full inline-block px-6 py-3 text-[18px];
}

.bottomBorder {
  @apply mx-4 mt-1 h-0.5 bg-white;
}

.loggedInRoute {
  @apply flex gap-4 items-center my-3 w-full px-6;
}

.loggedInRoute > a {
  @apply flex-grow;
}

.drawerList > a,
.drawerList > button {
  @apply w-[calc(100%-32px)] my-6 mx-4 py-[9.2px] rounded-lg border inline-block border-white text-center;
}

.drawerLogoutButton {
  @apply text-red-500 bg-white;
}
