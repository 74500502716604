.completedCheckInfoButtons {
  @apply flex gap-2 sm:gap-4 mt-2;
}

.completedCheckInfoButtons button {
  @apply rounded-lg py-1.5 w-[50%] bg-dodger-blue;
}

.completedCheckInfoButtons .cancel {
  @apply bg-opacity-5 text-dodger-blue text-lg;
}

.completedCheckInfoButtons .changeDate {
  @apply text-white;
}

.modal > img {
  @apply my-4 h-[100px] w-[100px];
}

.modal > span {
  @apply font-semibold mt-4;
}

.modal > textarea {
  @apply w-full border border-black h-[150px] rounded-lg py-2 px-4 resize-none my-4;
}

.modal > button {
  @apply w-full bg-dodger-blue text-white my-2 py-2 px-4 rounded-md disabled:bg-medium-gray;
}
