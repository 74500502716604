.dropDown {
  @apply relative;
}

.dropDown > button {
  @apply flex items-center justify-center pr-4 rtl:pr-0 rtl:pl-4 pl-0 text-sm font-medium text-[#5CA2FB] bg-white ring-2 ring-white rounded-md hover:bg-[#5CA2FB] hover:text-white;
}

.dropDown .pic {
  @apply h-10 aspect-square rounded-l-md rtl:rounded-l-none rtl:rounded-r-md mr-2 rtl:mr-0 rtl:ml-2 object-cover;
}

.dropDownMenu {
  @apply absolute right-0 rtl:left-0 rtl:right-auto mt-2 w-48 rounded-md shadow-lg bg-white border ring-1 ring-black ring-opacity-10 py-1;
}

.dropDownMenu a {
  @apply block px-4 py-2 text-sm text-gray-700 hover:bg-[#F6F6F6] hover:text-gray-900;
}

.dropDownMenu button {
  @apply w-full text-center px-4 py-2 text-sm font-medium text-white bg-[#0B74FA] rounded-md hover:bg-[#0B74FA]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0B74FA] transition-colors;
}
