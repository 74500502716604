.ourVision {
  @apply flex flex-col lg:flex-row lg:gap-[52px]  bg-[url('../../../assets/pics/homepage/ourVision.png')];
  background-size: 100% auto;
}

.ourVision > .doctorIm {
  @apply w-full lg:w-[40%] lg:basis-2/5 lg:object-contain lg:mb-4 lg:mt-6 relative z-10;
}

.ourVisionText {
  @apply flex flex-col items-center lg:items-start lg:justify-between;
}

.ourVisionText h3 {
  @apply lg:self-start;
}

.secIntro {
  @apply text-base;
}

.ourVisionIcon {
  @apply w-11 h-11 sm:w-16 sm:h-16;
}

.ourVisionSecs {
  @apply lg:flex gap-8;
}

.ourVisionSec {
  @apply flex items-center flex-col gap-2 mb-8 lg:items-start lg:max-w-[50%];
}

.seeServices {
  @apply px-4 py-2 mb-4 lg:px-8 lg:py-4;
}
