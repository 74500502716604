.jobCard {
  @apply flex gap-4 items-stretch flex-col xl:flex-row;
}

.jobCard > a {
  @apply xl:w-[30%] xl:min-w-[30%] xl:max-w-[30%] max-h-[200px] sm:max-h-[300px] md:max-h-[200px] lg:max-h-[300px] xl:max-h-[200px] min-h-[150px] inline-block rounded-2xl overflow-hidden;
}

.jobCard > a img {
  @apply h-full object-cover w-full;
}

.jobCardDes {
  @apply flex flex-col justify-between max-h-[180px];
}

.jobCardDes > span {
  @apply text-base sm:text-lg;
}

.jobSerWra {
  @apply flex flex-wrap gap-4 mt-2;
}

.jobSer {
  @apply py-1.5 px-3 rounded-full inline-block border;
}
