.bookingsLinks {
  @apply sm:px-4 lg:px-8 flex sm:gap-4 md:gap-7 lg:gap-10 justify-between;
}

.bookingsLinks a {
  @apply flex-grow border-r-0 sm:border-r-2 border-2 border-dodger-blue bg-dodger-blue text-dodger-blue bg-opacity-5 text-center py-3 sm:rounded-2xl;
}

.bookingsLinks .firstLink {
  @apply border-l-0 sm:border-l-2;
}

a.activeBookingSec {
  @apply bg-opacity-100 text-white;
}

.bookingsSec {
  @apply p-4 lg:p-8 grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-10;
}
