.mostImportantBlogs {
  @apply w-full mb-16 text-start;
}

.mostImportantBlogs > h2,
.mostImportantBlogs > p {
  @apply text-center;
}

.importantBlogs {
  @apply grid grid-cols-1 xl:grid-cols-9 xl:grid-rows-3 gap-x-8 gap-y-12 xl:gap-y-4;
}

.bigImportantBlog {
  @apply xl:col-span-4 xl:row-span-3 relative flex flex-col justify-between;
}

.bigImportantBlog .bigPic {
  @apply object-cover max-h-[350px] lg:max-h-[405px] w-full rounded-2xl h-full min-h-[250px];
}

.bigImportantBlog a {
  @apply py-3 px-6 bg-white rounded-2xl w-full inline-block text-center;
}

.marginY {
  @apply my-4;
}

.bigDes {
  @apply px-2;
}
