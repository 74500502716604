.bookingsCard {
  @apply p-2 border-2 rounded-2xl;
}

.bookingsFlexWra {
  @apply flex gap-4 items-stretch flex-col sm:flex-row md:flex-col lg:flex-row;
}

.bookingsDateInfo {
  @apply bg-dodger-blue bg-opacity-5 flex justify-between p-2.5 pt-0 rounded-lg flex-wrap;
}

.bookingsFlexWra > img {
  @apply sm:w-[30%] md:w-auto lg:w-[30%] object-contain rounded-lg;
}

.bookingsFlexWra > div {
  @apply mb-2 text-start flex flex-col gap-4 self-center  h-full flex-grow w-full;
}

.bookingsFlexWra > div > span {
  @apply text-base sm:text-xl;
}

.bookingsDateInfo {
  @apply bg-dodger-blue bg-opacity-5 flex justify-between gap-4 p-2.5 pt-0 rounded-lg flex-wrap max-w-[350px] sm:max-w-none;
}

.bookingsDateInfo > div {
  @apply flex gap-2.5 text-sm mt-2.5 text-nowrap;
}
