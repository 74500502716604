.readMore {
  @apply m-0 pt-36;
}

.readMorePaper {
  @apply relative m-0 sm:m-4 lg:mt-10 lg:m-8 p-4 lg:p-8 bg-none sm:bg-white rounded-2xl sm:shadow-[0px_0px_100px_-40px_#00000040];
}

.readMorePaper > p,
.readMorePaper > h2 {
  @apply text-center relative;
}

.reactButton {
  @apply p-2 lg:p-3 bg-[#0000000D] rounded-lg mr-2.5 lg:mr-4;
}

.buttons {
  @apply sm:absolute right-4 top-6 md:top-8 lg:top-10 text-center my-4;
}

.comments {
  @apply p-4 lg:p-8;
}

.comments > span {
  @apply font-bold mb-6 inline-block;
}

.addAComment {
  @apply flex items-start gap-5;
}

.comments img,
.comments svg {
  @apply inline-block min-w-[50px] min-h-[50px] w-[50px] h-[50px] rounded-full bg-[#D4D4D4];
}

.comments svg {
  @apply p-1;
}

.addACommentInput {
  @apply bg-transparent flex-grow resize-none py-2 border-b-2 border-[#D4D4D4] outline-none;
}
