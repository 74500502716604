.profileInfo {
  @apply text-base xl:text-lg  flex gap-8 flex-col lg:flex-row;
}

.profileInfo > img,
.profileInfo > svg {
  @apply lg:max-w-[500px] lg:min-w-[340px] lg:w-[30%] object-cover rounded-2xl;
}
.patientName {
  @apply flex gap-20 items-center;
}

.patientName h4 {
  @apply text-[#737377];
}

.patientName > h2 {
  @apply font-[500];
}

.medicalRecord p {
  @apply text-start;
}

.medicalInfo {
  @apply flex justify-between mb-12 mt-8 flex-wrap md:max-w-[80%];
}

.medicalInfo > div {
  @apply flex items-center gap-4 mt-4;
}
