@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-arial text-sm text-dark-blue sm:text-base bg-[#F7F7F7];
}

html::selection {
  @apply bg-gray-400 text-white;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

p {
  @apply text-[#737377];
}

.bgBlue {
  @apply rounded-2xl bg-dodger-blue text-white w-max;
}

.cardsWra {
  @apply flex gap-8 flex-wrap w-full lg:gap-[40px];
}

.save {
  @apply bg-white p-2 lg:p-2.5 rounded-lg;
}

.saveButton {
  @apply absolute top-4 left-4;
}

.secIntro {
  @apply text-base sm:text-[18px] md:text-[20px] mb-8;
}

.header2 {
  @apply text-[20px] font-bold my-2 mt-1 sm:text-[30px] md:text-[40px];
}

.hideScrollBar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hideScrollBar::-webkit-scrollbar {
  width: 0;
  display: none;
}

.importantText {
  @apply text-xl sm:text-2xl text-center;
}

img.iconWithBlueBg {
  @apply w-[32px] h-[32px] sm:w-[52px] sm:h-[52px] p-1.5 sm:p-3 bg-dodger-blue bg-opacity-5 rounded-[23px];
}
