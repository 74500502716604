.appointmentCard {
  @apply p-2 border-2 rounded-2xl mb-4;
}

.doctorInfo {
  @apply flex gap-4 items-center;
}

.doctorInfo > img {
  @apply h-24 w-24 rounded-lg;
}

.doctorInfo > div {
  @apply mb-2 text-start;
}

.doctorInfo > div > span {
  @apply text-base sm:text-xl;
}

.doctorInfo > div > p{
  @apply break-all hyphens-manual
}

.dateInfo {
  @apply bg-dodger-blue bg-opacity-5 flex justify-between p-2.5 pt-0 rounded-lg flex-wrap mt-2;
}

.dateInfo > div {
  @apply flex gap-2.5 text-sm mt-2.5;
}
