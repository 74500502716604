.starRating {
  @apply flex gap-2;
}

.plainStar {
  @apply relative;
}

.goldStar {
  @apply absolute top-0 left-0 rtl:left-auto rtl:right-0 overflow-hidden;
}

.setStarRating {
  @apply flex gap-1;
}

.setStarRating > button {
  @apply h-6 w-6;
}
