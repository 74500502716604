.checkoutForm {
  @apply mt-4 space-y-4 w-[400px] max-w-full;
}

.checkoutForm > button {
  @apply w-full py-2 mt-4 bg-dodger-blue text-white rounded disabled:disabled:bg-medium-gray disabled:cursor-not-allowed;
}

.stripeElement {
  @apply border p-2 rounded;
}
