nav {
  @apply text-base z-10 fixed w-full text-white;
}
.appBarPC {
  @apply hidden lg:block;
}

.appBarMo {
  @apply lg:hidden px-[5%] py-4 bg-dodger-blue;
}

.upperMoPart {
  @apply flex justify-between mb-4;
}

.upperPart {
  @apply flex items-center py-[19px] xl:gap-8 gap-6 px-8 relative z-10 bg-dodger-blue;
}

.upperPart > a {
  @apply w-[145px] py-[9.2px] rounded-lg border border-white text-center;
}

.lowerPart {
  @apply absolute transition-all duration-500 -bottom-[45px] bg-dodger-blue w-full z-0;
}

.translate {
  @apply bottom-0;
}

.miniFlex {
  @apply flex items-center  gap-[10px] whitespace-nowrap relative;
}

.miniFlex a {
  @apply min-w-[16px] h-full;
}

.routes {
  @apply flex border-t border-[#0C8BE9] divide-x rtl:divide-x-reverse;
}

.routes li a {
  @apply w-[180px]  py-2.5 px-6 inline-block text-center;
}

.activeLink {
  @apply bg-golden-yellow;
}
