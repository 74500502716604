.serviceCard {
  @apply sm:w-[380px] flex-grow flex flex-col justify-between;
}

.serviceCard .serviceImWra {
  @apply max-w-full rounded-2xl relative flex-grow max-h-[300px] mb-3 min-h-[200px];
}

.serviceImWra .serviceImage {
  @apply object-cover w-full rounded-2xl h-full;
}

.serviceImWra .whiteLink {
  @apply absolute right-4 bottom-4 py-3 px-6 bg-white rounded-2xl;
}
