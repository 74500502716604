.jobForm {
  @apply px-0 py-9 sm:p-9;
}

.jobForm input,
.jobForm textarea {
  @apply w-full border border-[#011329] rounded-lg p-2 mb-6;
}

.jobForm label {
  @apply text-base sm:text-xl;
}

.uploadWra {
  @apply border border-black rounded-xl p-8 text-center cursor-pointer mb-8;
}

.uploadWra > input {
  @apply hidden;
}

.uploadWra > img {
  @apply text-center w-full my-3 h-10;
}

.jobForm button {
  @apply p-[9px] bg-dodger-blue text-white w-full rounded-lg hover:bg-opacity-90 transition-colors disabled:bg-medium-gray;
}
