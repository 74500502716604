.rememberMe{
    @apply flex gap-2 px-2 py-4 items-center
}

input[type='checkbox']{
    @apply appearance-none w-4 h-4 relative rounded-[5px] border-2 hover:cursor-pointer checked:border-dodger-blue checked:bg-dodger-blue checked:rounded-[5px] focus:outline-none
}
input[type="checkbox"]:checked::before {
    content: "✔";
    @apply absolute text-white top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2
}