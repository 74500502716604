.search {
  @apply flex-grow relative text-black;
}

.search input {
  @apply rounded-lg w-full;
}

.search button {
  @apply absolute right-0 rtl:left-0 rtl:right-auto bg-golden-yellow rounded-lg h-full w-[65px];
}

.search svg {
  @apply absolute left-3 rtl:right-3 rtl:left-auto top-1/2 -translate-y-1/2;
}

.search button svg {
  @apply absolute left-1/2 rtl:right-1/2 rtl:left-auto -translate-x-1/2 rtl:translate-x-1/2 top-1/2 -translate-y-1/2;
}
