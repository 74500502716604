.ratingWra {
  @apply md:w-[50%] overflow-auto;
}

.ratingWraOverview {
  @apply pb-6 flex;
}

.seperate {
  @apply h-[3px] mb-6 bg-white;
}

.verticalSeperator {
  @apply w-[3px] mx-4 bg-white;
}

.generalOverview {
  @apply text-center;
}

.generalOverview > p {
  @apply mt-4;
}

.detailedOverview {
  @apply flex flex-col items-stretch justify-between flex-grow;
}
