.profile {
  @apply pt-36;
}

.bookings {
  @apply flex flex-col my-16;
}

.modalBackdrop {
  @apply fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50;
}

.modal {
  @apply bg-white rounded-lg shadow-lg px-6 sm:px-10 sm:py-4 py-2 relative w-[500px] max-w-[95%] sm:max-w-md  max-h-[510px] m-4 flex flex-col items-center;
}
