.chooseDate {
  @apply max-w-full h-full flex flex-col;
}
.chooseDate h4 {
  @apply mb-6;
}

.dateSelector {
  @apply mb-5;
}

.datesWra {
  @apply overflow-x-scroll flex;
}

.dateSelectButton {
  @apply border-b-[2px] pb-2.5 text-nowrap px-4;
}

.timeSlots {
  @apply space-y-8 mb-8;
}

.timeSlot {
  @apply space-y-3;
}

.timeSlot img {
  @apply mr-2 rtl:ml-2 rtl:mr-0;
}

.label {
  @apply flex items-center space-x-2 text-sm text-gray-600;
}

.timesGrid {
  @apply grid grid-cols-2 gap-3;
}

.timeSelectButton {
  @apply px-3 py-2 text-sm border rounded-full transition-colors border-blue-500;
}

.confirmButton {
  @apply w-full mt-auto mb-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white py-3;
}
