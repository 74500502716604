.departmentInfo {
  @apply space-y-8;
}

.departmentInfo p {
  @apply text-black;
}

.departmentInfo span {
  @apply font-bold;
}

.departmentInfo ol {
  @apply ml-10 list-decimal space-y-4 mt-6;
}

.blueCon {
  @apply bg-dodger-blue bg-opacity-10 p-8 rounded-3xl;
}

.headInfo {
  @apply space-y-4;
}

.jobHeadlines {
  @apply flex justify-between gap-4;
}

.jobHeadlines .importantText {
  @apply text-start;
}

.requirments {
  @apply space-y-4;
}
