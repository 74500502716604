.verification-title{
    @apply text-center
}

.timerWra{
    @apply text-center
}

.verification-inputs {
    @apply flex justify-center gap-2 mb-8 mt-16;
}

.verification-input {
    @apply border-none w-[16.5%] h-[52px] sm:h-[62px] sm:w-[62px] text-center text-2xl rounded-lg bg-soft-gray text-dark-blue;
}