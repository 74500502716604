.ratingCard {
  @apply pb-6;
}

.rater {
  @apply flex items-center mb-4 justify-between;
}

.rater > div {
  @apply flex gap-4 items-center;
}

.rater img {
  @apply h-10 w-10 rounded-full;
}

.stars {
  @apply flex gap-3 mt-4;
}

.stars > p,
.rater > p {
  @apply text-[#BFBFBF];
}
