.header{
    @apply absolute w-full p-4 lg:p-6 z-10
}

.header .logo-wra{
    @apply flex justify-center mt-[69px]
}

.back{
    @apply flex items-center gap-2.5
}

.back p{
    @apply hidden lg:block
}