.authLayout {
  @apply min-h-[100vh] flex flex-col font-rubik bg-white;
}

.authLayout span {
  @apply text-base sm:text-2xl;
}

.authH3 {
  @apply text-2xl sm:text-[32px] text-center mb-[22px] sm:w-[390px] self-center min-w-[290px];
}

.pageWra {
  @apply flex flex-col items-center lg:py-10;
}

.basis {
  @apply basis-[57%] min-w-[43%];
}

.page {
  @apply w-[311px] sm:w-[404px] px-4 relative flex flex-col;
}

.form {
  @apply flex flex-col justify-between;
}

.textInputsWra {
  @apply flex flex-col gap-2;
}

.gapOfEight {
  @apply gap-2;
}

.textInputsWra > div {
  @apply h-16 flex flex-col justify-end;
}

.input {
  @apply w-full relative rounded-lg self-end;
}

.input input {
  @apply w-full p-[11px] pl-10 bg-soft-gray rounded-lg text-base;
}

.input .icon {
  @apply absolute px-3 top-1/2 left-0 -translate-y-1/2;
}

.eye {
  @apply absolute px-3 top-1/2 right-0 -translate-y-1/2;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear,
input[type="password"]::-webkit-credentials-auto-fill-button {
  @apply hidden;
}
input[type="password"] {
  @apply pr-10;
}

.authLayout p {
  @apply text-inherit;
}

p.errorText {
  @apply text-red-500 text-xs;
}

.orangeUnderline {
  @apply text-dodger-blue underline underline-offset-2;
}

.rightImage {
  @apply hidden lg:block max-h-[100vh] overflow-hidden;
}

.authWra {
  @apply lg:flex items-center mt-[150px] lg:mt-[0px] lg:grow lg:max-h-[100vh] justify-center;
}

.authWraBg {
  @apply lg:bg-[url(../../assets/pics/auth/hall.webp)] bg-no-repeat bg-cover;
}

.authPaper {
  @apply bg-white rounded-[32px] lg:h-[500px] lg:w-[530px] flex items-center justify-center;
}
