div.checkInfo {
  @apply max-h-none md:max-h-full;
}
.checkInfo > img {
  @apply w-full;
}

div.checkInfoDoctor {
  @apply py-6 mb-6 space-y-2 border-b-2 border-[#D4D4D4];
}

.checkInfo .importantText {
  @apply text-start;
}

.checkInfoDate {
  @apply space-y-6;
}

.dateDets {
  @apply flex items-center gap-4;
}

.checkInfoButtons {
  @apply flex gap-6 mt-6;
}

.checkInfoButtons button {
  @apply rounded-lg py-3 w-[50%] bg-dodger-blue;
}

.checkInfoButtons .cancel {
  @apply bg-opacity-5 text-dodger-blue text-lg;
}

.checkInfoButtons .confirm {
  @apply text-white;
}
