.importantBlog {
  @apply xl:col-span-5 row-span-1 w-full flex flex-col md:flex-row gap-4 relative pb-12 md:pb-0  h-full min-h-[250px] xl:min-h-0;
}

.importantBlogPicSec {
  @apply md:relative z-0 md:min-w-[350px] md:w-[350px] xl:min-w-[250px] xl:w-[250px];
}

.importantBlog .save {
  @apply p-1.5;
}

.importantBlog .saveButton {
  @apply left-4 top-4 md:left-2 md:top-2 z-10;
}

.importantBlog a {
  @apply z-10 absolute bottom-0 md:bottom-2 md:right-2 bg-white w-full md:w-auto text-center py-3 px-6 md:py-1.5 md:px-4 rounded-lg;
}

.importantBlog .importantBlogPic {
  @apply object-cover w-full min-h-full h-full block  xl:absolute min-w-[237px] z-0 rounded-2xl;
}
